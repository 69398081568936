import { withRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";

import TabPane from "../../../../../../components/ubold/organisms/TabPane";
import TabPaneSection from "../../../../../../components/ubold/organisms/TabPaneSection";

import CHTextView from "components/ubold/molecules/forms/CHTextView";
import CHSelect from "components/ubold/molecules/forms/CHSelect";
import CHCheckBox from "components/ubold/molecules/forms/CHCheckBox";
import CHAsyncSelect from "components/ubold/molecules/forms/CHAsyncSelect";

import utils from "../../../../../../utils";
import CHDateTimePicker from "components/ubold/molecules/forms/CHDateTimePicker";
import moment from "moment";

import InvoiceTenantDetail from "../InvoiceDetails/index";
import COneOrManyToManyFieldV2 from "components/ubold/molecules/forms/COneOrManyToManyFieldV2";
import CHMultiRowView from "../../../../../../components/ubold/molecules/forms/CHMultiRowView";
import CHSingleRow from "../../../../../../components/ubold/molecules/forms/CHSingleRow";
import useGlobalState from "state";
import CButtonWithAlert from "../../../../../../components/ubold/atoms/buttons/CButtonWithAlert";

function Invoice(props) {
  const { id, definitions, tab, state, setState } = props;
  const todayPlusOne = moment().add(1, "days").format("YYYY-MM-DD");
  const [minEndDate, setMinEndDate] = useState(todayPlusOne);
  const [accesses] = useGlobalState("accesses");

  useEffect(() => {
    if (!state?.started_at) {
      setMinEndDate(todayPlusOne);
      return;
    }
    const newMinEndDate = moment(state?.started_at)
      .add(1, "day")
      .format("YYYY-MM-DD");
    setMinEndDate(newMinEndDate);
    return;
  }, [state?.started_at]);

  const tempDetail = state?.detail ? [...state?.detail] : [];
  if (tempDetail.length > 0) {
    tempDetail.forEach((detail) => {
      const period = Object.keys(detail)[0];
      const periodData = detail[period];
      state[`${period}-default`] = periodData;
    });
  }

  const generalKeys = state?.detail?.map(
    (tempDetail) => Object.keys(tempDetail)[0]
  );

  return (
    <TabPane
      id={id}
      definitions={definitions}
      tab={tab}
      hideAddAnother
      state={state}
      setState={setState}
      readOnly={state?.invoice_status === 2}
      enableDownloadBtn
      downloadTitleBtn="Download Invoice"
      downloadLink={state?.invoice_pdf_url}
      additionalButtonsBeforeMainButtons={[
        <CButtonWithAlert
          baseLink="finances_new/latest-payment-status/"
          failedMessage="Get latest status midtrans request is unsuccessful. Please try again"
          key="get-latest-midtrans-status-0"
          paramObject={{ invoice_id: id }}
          successMessage="Successfully get the latest status of midtrans"
          title="Get Latest Midtrans Status"
        />,
        accesses?.includes("finance_new.invoice.force_paid") && id ? (
          <CButtonWithAlert
            baseLink="finances_new/mark-as-paid/"
            confirmBtnText="Yes, I'm sure"
            dialogText="Are you sure to change this Invoice status without any payment?"
            dialogTitle="Mark Invoice as Paid"
            failedMessage="Mark this invoice as paid is unsuccessful. Please try again"
            key="max-invoice-0"
            paramObject={{ invoice_id: id }}
            successMessage="Successfully mark this invoice as Done"
            title="Mark as Paid"
            withDialog
            inputLabel="Please input your reason (required)"
            inputType="textarea"
            inputPlaceholder="Type your reason here..."
            inputFieldName="mark_as_paid_remark"
          />
        ) : (
          <></>
        ),
      ]}
    >
      <TabPaneSection style={{ padding: "25px 16px 0px" }}>
        {!id ? (
          <></>
        ) : (
          <CHTextView title="Invoice ID" name="number" />
        )}
        <CHSelect
          name="invoice_type"
          title="Invoice Type"
          readOnly
          data={{ optionField: "finance_new_invoice_type" }}
        />

        <CHAsyncSelect
          name="order"
          title="Order ID"
          data="order_new/order?__type__=select_entries&with_related=1"
          isRequired
          itemsExtractor={(row) => {
            // set selected order from endpoint response
            if (state?.order === row.id) {
              setState((prev) => ({
                ...prev,
                is_migration_data: row.is_migration_data,
              }));
            }

            return {
              label: row.number,
              value: row.id,
              building_name: row.building__building_name,
              tenant_name: row.tenant__name,
              room_name: row.room__name,
              is_migration_data: row.is_migration_data,
            };
          }}
          afterChange={(value) => {
            setState((prevState) => ({
              ...prevState,
              building_name: value?.building_name,
              tenant_name: value?.tenant_name,
              room_name: value?.room_name,
              is_migration_data: value?.is_migration_data,
            }));
          }}
          readOnly={id}
        />

        {!id ? (
          <CHDateTimePicker
            name="started_at"
            title="Started At"
            type="date"
            isRequired
            minDate={moment().format("YYYY-MM-DD")}
            readOnly
          />
        ) : (
          <CHTextView
            readOnly
            title="Started At"
            name="started_at"
            isRequired
            renderer={(value) => {
              return utils.formatter.date(value);
            }}
          />
        )}
        {!id ? (
          <CHDateTimePicker
            name="ended_at"
            title="Ended At"
            type="date"
            isRequired
            minDate={minEndDate}
            maxDate={moment(state?.started_at)
              .clone()
              .endOf("month")
              .format("YYYY-MM-DD")}
            readOnly
          />
        ) : (
          <CHTextView
            readOnly
            title="Ended At"
            name="ended_at"
            renderer={(value) => {
              return utils.formatter.date(value);
            }}
          />
        )}
        <CHTextView
          title="Building Name"
          name="building_name"
          readOnly
        />
        <CHTextView title="Room Name" name="room_name" readOnly />
        <CHTextView title="Tenant Name" name="tenant_name" readOnly />
        {!id ? (
          <></>
        ) : (
          <CHTextView
            readOnly
            title="Grand Total"
            name="grand_total"
            renderer={(value) => {
              return utils.formatter.currency(value);
            }}
          />
        )}
        <CHSelect
          title="Release Status"
          name="release_status"
          data={{
            optionField: "finance_new_invoice_release_status",
          }}
          readOnly={
            state?.invoice_status === 2 ||
            state?.original___release_status === 3
          }
        />

        {!id ? (
          <CHSelect
            name="category"
            title="Invoice Category"
            readOnly
            data={{
              optionField: "finance_new_invoice_detail_category",
            }}
          />
        ) : (
          <></>
        )}
        {!id ? (
          <CHSelect
            name="sub_category"
            title="Invoice Sub Category"
            readOnly
            data={{
              optionField: "finance_new_invoice_detail_sub_category",
            }}
          />
        ) : (
          <></>
        )}
        {!id ? (
          <></>
        ) : (
          <CHSelect
            title="Invoice Status"
            name="invoice_status"
            readOnly
            data={{
              optionField: "finance_new_invoice_status",
            }}
          />
        )}
        {!id ? (
          <></>
        ) : (
          <CHCheckBox
            title="First Invoice"
            name="first_invoice"
            readOnly
          />
        )}
      </TabPaneSection>
      {!id ? (
        <section
          style={{
            width: "100%",
            background: "#f2f2f2",
            height: 30,
            paddingRight: 24,
            paddingLeft: 29,
            alignItems: "center",
            fontSize: 16,
            fontWeight: 500,
            display: "flex",
          }}
        >
          Invoice Detail
        </section>
      ) : (
        <></>
      )}

      {!id ? (
        <COneOrManyToManyFieldV2
          id={id}
          name="t_invoice_details"
          saveOnlyFromMainForm
          relationKey="invoice_id"
          mainTab={true}
          columns={[
            {
              name: "item",
              title: "Invoice Item Name",
              type: "text",
              isRequired: true,
            },
            {
              name: "price",
              type: "number",
              title: "Invoice Item Amount (Rp)",
              isRequired: true,
              useCurrencyHelper: true,
            },
          ]}
        />
      ) : (
        <TabPaneSection accordionId="formAccordion">
          {generalKeys?.map((key, index) => {
            return (
              <InvoiceTenantDetail
                key={`${key}-${index}`}
                generalKey={key}
              />
            );
          })}
        </TabPaneSection>
      )}
      {id &&
      state?.referal &&
      Object.keys(state?.referal).length > 0 ? (
        <CHMultiRowView
          data={state?.referal}
          title="Referral"
          isDeduction
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {id && state?.vouchers?.length > 0 ? (
        <CHMultiRowView
          data={state?.vouchers}
          title="Voucher"
          multi={true}
          isDeduction
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {id ? (
        <CHSingleRow
          title="Grand Total"
          data={state?.grand_total}
          moreLeft
          titleWidth="57%"
        />
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </TabPane>
  );
}

export default withRouter(Invoice);
